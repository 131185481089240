import React from 'react';
import './ABLocations.scss';
import { Container, Row, Col } from "react-bootstrap";
import ABMapContainer from "./ABMapContainer";


const ABLocations = () => {
    const stores = [
        { key:0, title: '397 Arguello', open: true, phone: '415-750-9460', openEveryday:true, hours:{weekdays:'8a - 3p', weekends:'8a - 3:30p'}, coords: { lat: 37.7834259, lng: -122.4593062 } }, 
        { key:1, title: '87 McAllister', open: true, phone: '415-926-5155', openEveryday: true, hours:{weekdays:'8a - 3p', weekends:'8a - 3p'}, coords: { lat: 37.7808373, lng: -122.4133492 } },
        { key:2, title: '1070 Bridgeview', open: false, phone: null, openEveryday: false, hours:{weekdays:null, weekends:null}, coords: { lat: 37.77536893187453, lng: -122.38836500188651 } },
    ]
    return (
        <div className="locations-container content-item">
            <div className="store-card">
                <Container className="store-details">
                    <Row><Col><h2>{stores[0].title}</h2></Col></Row>
                    <Row><Col>Phone</Col><Col>{stores[0].phone}</Col></Row>
                    <Row><Col>Mon - Fri</Col><Col>{stores[0].hours.weekdays}</Col></Row>
                    <Row><Col>Sat &amp; Sun</Col><Col>{stores[0].hours.weekends}</Col></Row>
                </Container>
                <ABMapContainer coords={stores[0].coords} />
            </div>
            <div className="store-card">
                <Container className="store-details">
                <Row><Col><h2>{stores[1].title}</h2></Col></Row>
                    <Row><Col>Phone</Col><Col>{stores[1].phone}</Col></Row>
                    <Row><Col>Mon - Fri</Col><Col>{stores[1].hours.weekdays}</Col></Row>
                    <Row><Col>Sat &amp; Sun</Col><Col>{stores[1].hours.weekends}</Col></Row>
                </Container>
                <ABMapContainer coords={stores[1].coords} />
            </div>
            <div className="store-card">
                <Container className="store-details">
                <Row><Col><h2>{stores[2].title}</h2></Col></Row>
                <Row><Col>Coming Soon!</Col></Row>
                </Container>
                <ABMapContainer coords={stores[2].coords} />
            </div>
        </div>
    )
}

export default ABLocations